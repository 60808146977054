<template>
  <NuxtLink
    :to="dragging ? undefined : community.route"
    class="community-item"
    :class="{ flexible }"
  >
    <NuxtImg
      v-if="image"
      :src="image"
      :alt="community.title"
      width="250"
      height="400"
      quality="80"
      format="auto"
      class="background-img"
      :draggable="false"
    />
    <div class="gradient" />
    <h4>{{ capitalize(community.title) }}</h4>
    <p>
      {{ community.users_count }}
      {{ pluralize('membre', community.users_count) }}
    </p>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { CustomCommunityWithRoute } from '~/types'

const props = withDefaults(
  defineProps<{
    community: CustomCommunityWithRoute
    flexible?: boolean
    dragging?: boolean
  }>(),
  {
    flexible: false,
    dragging: false,
  },
)

const image = computed(() => {
  return (
    props.community?.image_portrait?.url ??
    props.community?.image_desktop?.url ??
    ''
  )
})
</script>

<style lang="scss" scoped>
.community-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 25rem;
  height: 40rem;
  padding: 3rem;
  color: inherit;
  text-align: left;
  text-decoration: none;
  background: #ccc;

  &.flexible {
    width: 100%;
  }

  .background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16.6rem;
    background: rgb(0 0 0);
    background: linear-gradient(
      0deg,
      rgb(0 0 0 / 1) 0%,
      rgb(51 51 51 / 0.6) 69%,
      rgb(84 84 84 / 0) 100%
    );
  }

  h4 {
    z-index: 1;
    color: $white;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.2rem;
  }

  p {
    z-index: 1;
    color: $white;
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}
</style>
